export default {
    ERROR: {
        ACCESS_FORBIDDEN: 'Access forbidden.',
        BAD_REQUEST: 'Something went wrong in the way that we formulate the Request to the server.',
        CONTACT_ADMIN_FOR_PERMISSIONS: 'Please contact your Admin to check your permissions.',
        RELOAD: 'Reload',
        RESULT_FOUND: '0 results found',
        TRY_SEARCH_AGAIN: 'Please try to change your search or remove filters',
        UNEXPECTED_ERROR_TABLE: 'An unexpected error has occurred while loading the table',
    },
    SORT_ASCENDING: 'sorted ascending',
    SORT_DESCENDING: 'sorted descending',
};
