import { useEffect, useState } from 'react';

import { CSVDataFormat, FetchNextPage } from '../types';
import { downloadCsv } from './downloadCsv';

type ModalState = 'initial' | 'progress' | 'success' | 'error';

type UseExportModalParams<T> = {
    hasNextPage: boolean | undefined;
    setClose: () => void;
    fetchNextPage: FetchNextPage<T>;
    isError: boolean;
    isFetching: boolean;
    data: CSVDataFormat;
    emptyData: CSVDataFormat;
    fileNamePrefix: string;
};

const hasNextPagePropertyExists = (obj: unknown): obj is { hasNextPage: boolean } =>
    typeof obj === 'object' && obj !== null && 'hasNextPage' in obj;

export function useExportModal<T>({
    data,
    emptyData,
    fetchNextPage,
    fileNamePrefix,
    hasNextPage,
    isError,
    isFetching,
    setClose,
}: UseExportModalParams<T>) {
    const [state, setState] = useState<ModalState>('initial');

    const isShowCloseIcon = state === 'initial' || state === 'success' || state === 'error';

    const hasExportFinished = !!data.length && hasNextPage === false;

    const handleExport = async () => {
        const fetchNextPageRecursively = async () => {
            const res = await fetchNextPage();
            if (hasNextPagePropertyExists(res) && res.hasNextPage) {
                await fetchNextPageRecursively();
            }
        };

        if (hasNextPage) {
            await fetchNextPageRecursively();
        }
    };

    const handleDownload = () => {
        if (!isFetching) {
            downloadCsv({
                data: data.length ? data : emptyData,
                date: new Date(),
                prefix: fileNamePrefix,
            });
        }
    };

    const handleProceedExport = async () => {
        if (!hasNextPage) {
            setState('success');
            return;
        }
        setState('progress');
        await handleExport();
    };

    const handleTryAgainClick = async () => {
        await handleProceedExport();
    };

    const handleDownloadClick = () => {
        handleDownload();
        setClose();
    };

    useEffect(() => {
        if (hasExportFinished) {
            setState('success');
        }

        if (!hasExportFinished) {
            setState('initial');
        }
    }, [hasExportFinished]);

    useEffect(() => {
        if (isError) {
            setState('error');
        }
    }, [isError]);

    return {
        handleDownloadClick,
        handleProceedExport,
        handleTryAgainClick,
        isShowCloseIcon,
        state,
    };
}
