export default {
    BACK_TO_EDITING: 'Back to edit',
    BULK_CREATION: 'Bulk creation',
    CANCEL: 'Cancel',
    CONFIRM_AND_CREATE: 'Confirm and create',
    COUNTRIES: 'Countries',
    CREATE_NEW: 'Create new',
    DELIMITER: 'Use , as a delimiter',
    EDIT: 'Edit',
    GENERATE_LINK: 'Generate activation link',
    INTERNAL_NOTES: 'Internal notes',
    NOTES: 'Notes',
    OPTIONAL: '(optional)',
    PREVIOUS_STEP: 'Previous step',
    REMOVE: 'Remove',
    VIEW_DETAILS: 'View details',
};
