import {
    Popper,
    List,
    Grow,
    Paper,
    ClickAwayListener,
    Box,
    PopperPlacementType,
} from '@mui/material';

export type ActionMenuItemProps = {
    content: string | React.ReactNode;
    id: string;
    isHidden?: boolean;
};

type Props = {
    isOpen: boolean;
    onClose: (event: Event | React.SyntheticEvent) => void;
    menuItems: ActionMenuItemProps[];
    place?: PopperPlacementType;
    anchorRef: React.RefObject<HTMLButtonElement>;
};

export function MenuList({ anchorRef, isOpen, menuItems, onClose, place = 'left' }: Props) {
    return (
        <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            placement={place}
            transition
            disablePortal
            sx={{ zIndex: '2' }}
        >
            {({ placement, TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onClose}>
                            <List id="actions-menu" aria-labelledby="actions-button">
                                {menuItems
                                    .filter(({ isHidden = false }) => !isHidden)
                                    .map(({ content, id }) => (
                                        <Box key={id}>{content}</Box>
                                    ))}
                            </List>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
