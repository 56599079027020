import type { ThemeOptions } from '@mui/material/styles';

import { DockColor } from '@dock/common';

const xlBreakpoint = 1440;
const xxlBreakpoint = 1920;

const LIGHT = 300;
const REGULAR = 400;
const MEDIUM = 500;
const BOLD = 800;

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
        xxl2: true;
    }
}

export const DEFAULT_THEME: ThemeOptions = {
    breakpoints: {
        values: {
            lg: 1200,
            md: 900,
            sm: 600,
            xl: xlBreakpoint,
            xs: 0,
            xxl: xxlBreakpoint,
            xxl2: 2560,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    padding: '16px 40px',
                },
                sizeMedium: {
                    padding: '12px 32px',
                },
                sizeSmall: {
                    fontSize: 14,
                    padding: '8px 24px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': {
                    fontDisplay: 'swap',
                    fontFamily: 'Avenir',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    src: `local('Avenir'), url(/fonts/Avenir-Medium.woff2) format('woff2')`,
                },
                fallbacks: [
                    {
                        '@font-face': {
                            fontDisplay: 'swap',
                            fontFamily: 'Avenir',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            src: `local('Avenir'), url(/fonts/Avenir-Light.woff2) format('woff2')`,
                        },
                    },
                ],
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1.5px solid ${DockColor.GREY_200}`,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .Mui-disabled': {
                        backgroundColor: DockColor.GREY_500,
                        borderRadius: '4px',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .Mui-disabled': {
                        backgroundColor: DockColor.GREY_500,
                        borderRadius: '4px',
                    },
                    '& .MuiFormLabel-root': {
                        background: 'transparent',
                    },
                },
            },
        },
    },
    palette: {
        background: {
            default: DockColor.WHITE,
            paper: DockColor.WHITE,
        },
        error: {
            main: '#F8374A',
        },
        grey: {
            '100': DockColor.GREY_100,
            '200': DockColor.GREY_200,
            '300': DockColor.GREY_300,
            '400': DockColor.GREY_400,
            '50': DockColor.GREY_50,
            '500': DockColor.GREY_500,
            '600': DockColor.GREY_600,
            '700': DockColor.GREY_700,
            '800': DockColor.GREY_800,
        },
        info: {
            main: DockColor.GREY_800,
        },
        mode: 'light',
        primary: {
            dark: '#00367F',
            light: DockColor.PRIMARY_100,
            main: DockColor.PRIMARY_500,
        },
        secondary: {
            dark: '#0083D8',
            light: '#E0F3FC',
            main: '#009FE3',
        },
        success: {
            main: '#38CB4A',
        },
        text: {
            primary: DockColor.GREY_800,
        },
        warning: {
            light: '#FFB82E',
            main: '#FFCC00',
        },
    },
    typography: {
        body1: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 16,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 18,
            },
            fontSize: 14,

            fontWeight: MEDIUM,

            lineHeight: '20px',
        },
        body2: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 14,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 16,
            },
            fontSize: 12,

            fontWeight: MEDIUM,

            lineHeight: '16px',
        },
        button: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 16,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 18,
            },
            fontSize: 14,
            fontWeight: MEDIUM,

            lineHeight: '24px',

            textTransform: 'none',
        },
        fontFamily: [
            'Avenir',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontWeightBold: BOLD,
        fontWeightLight: LIGHT,
        fontWeightMedium: MEDIUM,
        fontWeightRegular: REGULAR,
        h1: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 40,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 42,
            },

            fontSize: 38,

            fontWeight: MEDIUM,
        },
        h2: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 26,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 28,
            },
            fontSize: 24,

            fontWeight: MEDIUM,

            lineHeight: '34px',
        },
        h3: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 20,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 22,
            },

            fontSize: 18,

            fontWeight: MEDIUM,
        },
        h4: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 18,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 20,
            },

            fontSize: 16,

            fontWeight: MEDIUM,
        },
        subtitle1: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 16,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 18,
            },
            fontSize: 14,

            fontWeight: MEDIUM,

            lineHeight: '20px',
        },
        subtitle2: {
            [`@media (min-width:${xlBreakpoint}px)`]: {
                fontSize: 14,
            },
            [`@media (min-width:${xxlBreakpoint}px)`]: {
                fontSize: 16,
            },
            fontSize: 12,

            fontWeight: MEDIUM,

            lineHeight: '16px',
        },
    },
};
