import { SxProps, Theme } from '@mui/material';
import { Controller, Control } from 'react-hook-form';

import { Input } from './Input';

type ControlledInputProps = {
    name: string;
    // TODO: consider to change any to specific type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    isFullWidth?: boolean;
    label: string;
    disabled?: boolean;
    maxLength?: number;
    helperText?: string;
    type?: string | undefined;
    testId?: string;
    sx?: SxProps<Theme>;
    autoFocus?: boolean;
};

export function ControlledInput({
    autoFocus = false,
    control,
    disabled,
    helperText,
    isFullWidth = false,
    label,
    maxLength,
    name,
    sx = {},
    testId,
    type,
}: ControlledInputProps) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Input
                    {...rest}
                    inputProps={{ inputRef: ref }}
                    label={label}
                    errorMessage={error?.message}
                    isFullWidth={isFullWidth}
                    disabled={!!disabled}
                    maxLength={maxLength}
                    helperText={helperText}
                    type={type}
                    testId={testId}
                    sx={sx}
                    autoFocus={autoFocus}
                />
            )}
        />
    );
}
